.authent {
    box-shadow: 0px 20px 30px 3px rgba(0, 0, 0, 0.55);
    display: none;
    background: #35394a;
    /* Old browsers */
    /* FF3.6+ */
  
    /* Chrome10+,Safari5.1+ */
    /* Opera 11.10+ */
    /* IE10+ */
    background: linear-gradient(230deg, rgba(53, 57, 74, 0) 0%, rgb(0, 0, 0) 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(53, 57, 74, 0)', endColorstr='rgb(0, 0, 0)',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */
    position: absolute;
    left: 0;
    right: 90px;
    margin: auto;
    width: 100px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    padding: 20px 70px;
    top: 200px;
    bottom: 0;
    height: 70px;
    opacity: 0;
  }
  .authent p {
    text-align: center;
    color: white;
    color: #D3D7F7;
    font-size: 14px;
    text-align: left;
  }
  .success {
    display: none;
    color: #d5d8e2;
  }

   .testtwo {
    left: -320px !important;
  }
   .test {
    box-shadow: 0px 20px 30px 3px rgba(0, 0, 0, 0.55);
    pointer-events: none;
    top: -100px !important;
    -webkit-transform: rotateX(70deg) scale(0.8) !important;
            transform: rotateX(70deg) scale(0.8) !important;
    opacity: .6 !important;
    -webkit-filter: blur(1px);
            filter: blur(1px);
  }
   .login 
  {
    box-shadow: -15px 15px 15px rgba(6, 17, 47, 0.7);
    opacity: 1;
    top: 20px;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.25, 0.265, 0.85);
    -webkit-transition-property: -webkit-transform,opacity,box-shadow,top,left;
            transition-property: transform,opacity,box-shadow,top,left;
    -webkit-transition-duration: .5s;
            transition-duration: .5s;
    -webkit-transform-origin: 161px 100%;
        -ms-transform-origin: 161px 100%;
            transform-origin: 161px 100%;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    position: fixed;
    width: 320px;
    /*border-top: 2px solid #D8312A;*/
    height: 440px;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    padding: 100px 40px 40px 40px;
    background: #35394a;
    /* Old browsers */
    /* FF3.6+ */
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #35394a), color-stop(100%, rgb(0, 0, 0)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(230deg, rgba(53, 57, 74, 0) 0%, rgb(0, 0, 0) 100%);
    /* Chrome10+,Safari5.1+ */
    /* Opera 11.10+ */
    /* IE10+ */
    background: linear-gradient(230deg, rgba(53, 57, 74, 0) 0%, rgb(0, 0, 0) 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(53, 57, 74, 0)', endColorstr='rgb(0, 0, 0)',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */
  }
   .login .validation {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 14px;
    opacity: 0;
  }
   .login .disclaimer {
    position: absolute;
    bottom: 20px;
    left: 35px;
    width: 250px;
  }
   .login_title {
    color: #D3D7F7;
    height: 60px;
    text-align: left;
    font-size: 16px;
  }
   .login_fields {
    height: 208px;
    position: absolute;
    left: 0;
  }
   .login_fields .icon {
    position: absolute;
    z-index: 1;
    left: 36px;
    top: 8px;
    opacity: .5;
  }
   .login_fields input[type='password'],body .login_fields input[type='text'] {
    color: #61BFFF !important;
  }
   .login_fields input[type='text'], body .login_fields input[type='password'] {
    color: #afb1be;
    width: 190px;
    margin-top: -2px;
    background: rgba(57, 61, 82, 0);
    left: 0;
    padding: 10px 65px;
    border-top: 2px solid rgba(57, 61, 82, 0);
    border-bottom: 2px solid rgba(57, 61, 82, 0);
    border-right: none;
    border-left: none;
    outline: none;
    font-family: 'Gudea', sans-serif;
    box-shadow: none;
  }
   .login_fields__user,  .login_fields__password {
    position: relative;
  }
   .login_fields__submit {
    position: relative;
    top: 17px;
    left: 0;
    width: 80%;
    right: 0;
    margin: auto;
  }
   .login_fields__submit .forgot {
    float: right;
    font-size: 10px;
    margin-top: 11px;
    text-decoration: underline;
  }
   .login_fields__submit .forgot a {
    color: #606479;
  }
   .login_fields__submit input {
    border-radius: 50px;
    background: transparent;
    padding: 10px 50px;
    border: 2px solid #4FA1D9;
    color: #4FA1D9;
    text-transform: uppercase;
    font-size: 11px;
    -webkit-transition-property: background,color;
            transition-property: background,color;
    -webkit-transition-duration: .2s;
            transition-duration: .2s;
  }
   .login_fields__submit input:active {
    box-shadow: none;
    outline: none;
  }
   .login_fields__submit input:active {
    color: white;
    background: #4FA1D9;
    cursor: pointer;
    -webkit-transition-property: background,color;
            transition-property: background,color;
    -webkit-transition-duration: .2s;
            transition-duration: .2s;
  }
  
  /* Color Schemes */
  .love {
    position: absolute;
    right: 20px;
    bottom: 0px;
    font-size: 11px;
    font-weight: normal;
  }
  .love p {
    color: white;
    font-weight: normal;
    font-family: 'Open Sans', sans-serif;
  }
  .love a {
    color: white;
    font-weight: 700;
    text-decoration: none;
  }
  .love img {
    position: relative;
    top: 3px;
    margin: 0px 4px;
    width: 10px;
  }
  
  .brand {
    position: absolute;
    left: 20px;
    bottom: 14px;
  }
  .brand img {
    width: 30px;
  }
.foo{
    color: #FFF;
    text-align: center;
    font-size: 12px;
    position: fixed;
    bottom: 12px;
    text-align: center;
    left: 0;
    right: 0;
}