// rem 单位换算：定为 75px 只是方便运算，750px-75px、640-64px、1080px-108px，如此类推
$vm_fontsize: 75; // iPhone 6尺寸的根元素大小基准值
@function rem($px) {
     @return ($px / $vm_fontsize ) * 1rem;
}
// 根元素大小使用 vw 单位
$vm_design: 750;
*{margin: 0;padding: 0}
html{
    width: 100%;
    height: 100%;
    font-size: ($vm_fontsize / ($vm_design / 2)) * 100vw; 
    body{
        width: 100%;
        height: 100%;
        // background-color: rgb(245, 245, 249);
        overflow: hidden;
        color: #333;
    }
}