body,
html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    height: 100%;
}
/* styles.css */
.custom-collapse {
    border: none;
    overflow: hidden;
}

.custom-collapse .ant-collapse-item {
    border-bottom: none;
}

.custom-collapse .ant-collapse-content {
    transform: rotateX(180deg);
}

.custom-collapse .ant-collapse-content-box {
    transform: rotateX(180deg);
}

.cutpiece_menu {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background-color: #000;
    color: #fff;
    width: 100%;
    line-height: 40px;
}

.return-link {
    text-decoration: none;
    color: #FFFFFF;
    font-size: 16px;
    margin-left: 16px;
    border: none;
    /* 移除边框 */
    background-color: transparent;
    cursor: pointer;
    position: relative;
    /* 用于定位伪元素 */
    transition: all 0.3s ease;
    /* 过渡动画 */
}

.return-link:hover {
    color: #fff;
}

.return-link::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 5px;
    width: 100%;
    height: 4px;
    /* 高光条的高度 */
    background-color: #007bff;
    transform: scaleX(0);
    /* 初始状态不显示高光条 */
    transform-origin: bottom left;
    transition: transform 0.3s ease;
}

.return-link:hover::after {
    transform: scaleX(1);
    /* 鼠标悬停时显示高光条 */
}

.userStyle {
    display: flex;
    color: white;
    margin-right: 16px;
}

.user_log {
    color: white;
    font-size: 20px;
}

.log_out {
    color: #FFFFFF;
    font-size: 20px;
    background-color: transparent;
    transition: color 0.3s ease;
    /* 添加过渡效果 */
    margin-left: 40px;
    border: none;
}

.log_out:hover {
    color: #007BFF;
    /* 鼠标悬停时改变文本颜色 */
}

.content-sections {
    display: flex;
    height: 100%;
    width: 100%;
}

.content-section-1 {
    width: 50%;
    height: 100%;
    padding: 10px;
    flex-direction: column;
}

.content-section-2 {
    position: relative;
    width: 50%;
    height: 100%;
    background-image: linear-gradient(90deg, #e2e2e2 1px, transparent 0), linear-gradient(#e2e2e2 1px, transparent 0);
    background-size: 30px 30px;
    background-color: #e6e6e6;
}

.content-section-2-1 {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    /* width: 20vw !important;
    height: 20vw !important; */
}
.content-section-2-3 {
    position: absolute;
    right: 0;
    z-index: 2;
    width: 10vw !important;
    height: 2vw !important;
}
.content-section-2-2 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    object-fit: contain; /* 确保内容不被裁剪 */
    transform-origin: top left;
}

.text-container {
    width: 100%;
}

.text-container span {
    font-size: 22px;
    color: #333;
    margin-right: 10px;
    display: flex;
}

.content-section-1-1 {
    font-size: 25px;
    color: rgba(98, 135, 255, 1);
}

.custom-progress {
    top: 20px;
    position: relative;
}

.custom-progress .ant-progress-bg {
    height: 10px !important;
    background-image: linear-gradient(90deg,
    rgba(103, 204, 255, 1) 0,
    rgba(98, 135, 255, 1) 100%);
}

.custom-progress .ant-progress-outer {
    padding-right: 22px;
    padding-left: 10px;
}

.custom-progress .ant-progress-text {
    position: absolute;
    top: -1em;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    color: rgba(98, 135, 255, 1);
}

.BeginButtion {
    width: 20vw;
    height: 3vw;
    background-image: linear-gradient(90deg,
    rgba(103, 204, 255, 1) 0,
    rgba(98, 135, 255, 1) 100%);
    color: #fffcfd;
    position: absolute;
    bottom: 4vw;
    left: 12vw;
    border-radius: 10px;
    border: none;
    font-size: 1vw;
}
/* 选择按钮样式 */
.stepTypeButton {
    width: 100px;
    height: 3vw;
    background-color: #f8f9fa;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1vw;
    transition: background-color 0.3s, color 0.3s;
    z-index: 999;
}

.stepTypeButton:hover {
    background-color: #007bff;
    color: #fff;
}

.stepTypeButton:active {
    background-color: #0056b3;
    color: #fff;
}
.content-section-1-2 {
    display: flex;
    flex-direction: column;
    width: 100%;
}
/* 隐藏滚动条 */
.scrollable-edge-line-blocks::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge, Opera */
}

.scrollable-edge-line-blocks {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    overflow-y: auto; /* 确保内容可以滚动 */
}
.risk_warning {
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
}

.risk_warning_title {
    font-weight: bold;
    color: red
}

.risk_warning_content {
    color: red
}
.process_classify {
    height: 50px;
    width: 90%;
    background-color: #6287FF;
    color: #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 13px;
    border-radius: 8px;
    margin-top: 2vw;
    margin-left: 2vw;
}

.process_classify button {
    padding: 5px 10px;
    margin: 0;
    background-color: inherit;
    color: inherit;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.process_classify button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.process_classify button.selected {
    background-color: #ffffff;
    color: #6287FF;
    border: 2px solid #6287FF;
}
.craft-item {
    display: flex;
    align-items: center;
    margin-bottom: 1px;
    color: white;
    background-color: #007bff;
    height: 30px;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s;
}

.craft-item:hover {
    background-color: #0056b3;
}
.audio_icon {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    width: 70px; /* 圆的直径 */
    height: 70px; /* 圆的直径 */
    border-radius: 50%;
    background-color: rgba(103, 204, 255, 1); /* 圆的背景颜色 */
}

.audio_title {
    margin-right: auto; /* 将标题推到最左边 */
    font-size: 22px;
}

.audio {
    display: flex;
    padding: 10px; /* 可选：添加一些内边距 */
    height: 200px;
}

.audio_icon_container {
    flex: 1; /* 占据剩余空间 */
    display: flex;
    justify-content: center; /* 水平居中 */
}
.audio_player {
    /* display: none; 默认隐藏音频播放器 */
    margin-top: 10px; /* 可选：添加一些间距 */
}

.audio_icon.active + .audio_player {
    display: block; /* 当图标被点击时显示音频播放器 */
}
.step_style {
    position: absolute;
    display: flex;
    width: 22%;
    color: #fffcfd;
    justify-content: space-around;
    align-items: center;
    bottom: 4vw;
    left: 20vw;
    gap: 23%;
}

.lastButtion,
.nextButton{
    width: 10vw;
    height: 5vh;
    background-color: #4d95e3;
    color: #fffcfd;
    border-radius: 25px;
    border: none;
    font-size: 1vw;
}

.scrollable-edge-line-blocks {
    overflow-y: auto;
    padding-right: 6px;
    width: 100%;
    height: 60vh;
    margin-top: 5%;
}

.progress_list {
    position: relative;
    padding: 8px;
    border-radius: 5px;
    margin: 10px;
}

.progress_data {
    font-size: 22px;
}

.progress_data_title {
    font-size: 20px;
    color: rgba(98, 135, 255, 1);
}

.data_parameters {
    background-image: linear-gradient(90deg, rgba(103, 204, 255, 1) 0, rgba(98, 135, 255, 1) 100%);
    padding: 3px 8px;
    margin: center;
    color: #fff;
    border-radius: 12px;
    font-size: 22px;
    display: flex;
    flex-direction: column;
}

.progress_data_container {
    display: flex;
    justify-content: space-between;
}

.progress_title {
    font-size: 18px;
}

.progress_title span {
    color: rgba(98, 135, 255, 1);
}

.parameters_image {
    width: 260px;
    height: 260px;
    display: flex;
    margin: 0 auto;
    margin-top: 10px;
}